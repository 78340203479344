@import "../index.css";

.countries-app {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
    color: #000;
}

.country-search {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 64px;
}

.country-text {
    margin-bottom: 32px;
    text-align: center;
}

.country-input {
    padding-left: 16px;
    width: 300px;
    height: 50px;
    border-radius: 4px;
    background-color: var(--secondary);
    color: var(--additional);
    font-size: 18px;
}

.country-input::placeholder {
    color: var(--additional);
}

.country-input:focus {
    box-shadow: 0 0 15px 5px var(--additional);

}

.info-text {
    margin-top: 10px;
    margin-bottom: -10px;
    font-weight: bold;
    color: #ff1111;
}

.filter-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.filter-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.filter-header {
    letter-spacing: 0.1rem;
}

.filter-icons {
    display: flex;
}

.non-active-filter, .active-filter {
    padding: 8px;
    border-radius: 100%;
    cursor: pointer;    
    border: none;
}

#alphabetically, #filteringLanguage, #filteringCurrency {
    font-size: 20px;
}

.non-active-filter + .non-active-filter, .active-filter + .active-filter {
    margin-left: 5px;
}

.non-active-filter {
    background: transparent;
}

.active-filter {
    background: var(--additional);
}

.countries-container {
    display: flex;
    justify-content: center;
}

.countries-wrapper {
    display: flex;
    align-items: center;
    flex: 3;
}

.countries-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    list-style: none;
}

@media only screen and (min-width: 768px){
    .countries-items {
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (min-width: 992px) {
    .countries-items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex-grow: 2;
    }
}

@media only screen and (min-width: 1200px) {
    .countries-items {
        display: flex;
        flex-direction: row;
        flex-grow: 4;
    }
}