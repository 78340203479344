:root {
    --primary: #daf7dc;
    --secondary: #9ee493;
    --additional: #32908F;
}

.navbar {
    background-color: var(--secondary);
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    z-index: 999;
    top: 0;
}

.nav-menu {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    list-style: none;
}

.nav-item {
    display: flex;
}

.nav-home, .nav-active {
    color: #fff;
    display: flex;
    align-items: center;
    margin-left: 10px;
    text-decoration: none;
}

.nav-not-active {
    display: none;
}

.btn {
    padding: 8px 20px;
    background-color: var(--additional);
    border: none;
    border-radius: 4px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease-out;
}

.btn:hover {
    transition: all 0.3s ease-out;
    background-color: #fff;
    color: var(--secondary);
}