@import "../index.css";

.country-item {
    display: flex;
    max-height: calc(100%);
    height: 100%;
    margin: 1rem;
    border-radius: 10px;
}

.country-item-link {
    padding: 0.8rem;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.3);
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
    transition: all 0.3s ease-out;
}

.country-item-link:hover {
    transform: scale(1.1);
    transition: all 0.3s ease-out;
}

.country-item-link-wrap {
    display: flex;
}

.country-item-img {
    width: 100%;
    height: 100%;
}

.country-short-info {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.country-label {
    display: flex;
    align-self: flex-start;
    color: var(--additional);
}

.label-value {
    font-weight: bold;
    margin-left: 4px;
}